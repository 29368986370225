@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Global Font style and Font Family */

$font-family-base: "Roboto";
$font-size-base: 0.9375rem;
$line-height-base: 1.5;

$font-weight-light: 300;
$font-weight-normal: normal;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

/* h1 to h6 font size */

$h1-font-size: 1.5rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1.125rem;
$h4-font-size: 1rem;
$h5-font-size: 0.875rem;
$h6-font-size: 0.75rem;

$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;
$small-font-size: 0.875rem;
/* Heading line height */
$headings-line-height: 1.2;
p {
  font-size: $font-size-base;
}
.font-weight-medium {
  font-weight: 500;
}
.font-weight-normal {
  font-weight: normal;
}
