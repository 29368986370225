$font-family-sans-serif: "Roboto", "Open Sans", sans-serif;
$headings-font-family: "Roboto", "Open Sans", sans-serif;
$headings-color: #262626;
$enable-responsive-font-sizes: true;
$enable-shadows: true;
$blue: #3f7cca;
$primary: $blue;
$secondary: #2c61a5;
$link-color: $secondary;
$black: #262626;
$info: #b9b9b9;
$danger: #eb4141;
$dark: #212f46;
$light: #e2e2e2;
$component-active-bg: $secondary;
$table-hover-bg: rgba($secondary, 0.075);
$table-accent-bg: rgba($secondary, 0.05);
$table-striped-order: null;
$nav-tabs-link-active-color: $primary;
$link-decoration: none;
$link-hover-decoration: none;
$custom-select-indicator: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='15' height='8' viewBox='0 0 15 8'> <path id='prefix__Down_arrow' d='M7.5 105.14a1.085 1.085 0 0 1-.743-.288L.308 98.818a.939.939 0 0 1 0-1.39 1.1 1.1 0 0 1 1.486 0l5.707 5.34 5.707-5.34a1.1 1.1 0 0 1 1.485 0 .938.938 0 0 1 0 1.39l-6.45 6.035a1.085 1.085 0 0 1-.743.287z' transform='translate(-.001 -97.14)' style='fill:#262626' /> </svg>");
$custom-select-bg-size: 15px 8px;
// /** Global color variable */
$warning: #efb73a;
$gray-600: #717477;
$gray-400: #707070;
$gray-700: #262626;

$body-color: #212f46;

$nav-pills-link-active-bg: #ededed;
$nav-pills-border-radius: none;

// /* Global border radius */

/* Breadcrumb Color  */
$breadcrumb-divider-color: #909090;
$breadcrumb-bg: #ffffff;
$breadcrumb-margin-bottom: 0rem;

// /* Button Field Color */

$btn-border-radius-lg: 0.188rem;
$input-btn-font-family: $font-family-base;
$input-btn-focus-color: none;
$btn-line-height-lg: 1.9rem;

// /* Input field Color */
$input-placeholder-color: #b9b9b9;
$input-border-color: $light;
$input-btn-font-size-lg: $font-size-base;
$input-height-lg: calc(1.7em + 1rem + 2px);
$input-disabled-bg: #efefef;
$input-box-shadow: none;
$input-btn-font-family: $font-family-base;
$input-btn-focus-color: none;
$btn-line-height-lg: 1.9rem;
$input-btn-font-size-lg: $font-size-base;
$input-height-lg: calc(1.7em + 1rem + 2px);
$btn-border-radius-lg: 0.188rem;
$btn-active-box-shadow: 0px;
$spinner-width: 1.5rem;
$spinner-height: 1.5rem;
$spinner-border-width: 0.21em;
$btn-box-shadow: none;

/* Toggle */

/* Checkbox and radio button */
$custom-control-indicator-checked-border-color: $primary;
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-focus-box-shadow: none;

/* Modal */
$modal-inner-padding: 1.5rem;
$modal-header-border-color: none;
$modal-content-border-radius: 10px;
$modal-content-box-shadow-xs: 0 3px 12px 0 rgba(0, 0, 0, 0.11);
$modal-header-padding-x: 1.5rem;
$modal-header-padding-y: 0.95rem;
$modal-title-line-height: $line-height-base;

/* Tabs */
$nav-tabs-border-color: #ededed;
$nav-tabs-link-active-color: $primary;
$nav-tabs-border-radius: none;

// /* Dropdown */
$dropdown-link-hover-bg: #f0f6ff;
$dropdown-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
$dropdown-font-size: 14px;
$dropdown-min-width: 9rem;

/** Card */
$card-border-color: #e2e2e2;
$badge-font-weight: normal;
$badge-font-size: 87.5%;
.badge-bg-light {
  color: #efefef;
}
.global-badge {
  &:hover {
    background-color: #d6d6d6;
  }
}
.text-dark-gray {
  color: #989898;
}
small {
  font-size: 14px;
}
.bg-login-light {
  background-color: #f7f9ff;
}
.back-arrow-hover {
  &:hover {
    background-color: #f7f9ff;
  }
}
.bg-light-blue {
  background-color: #d6e6ff;
}
.card-bottom-text-color {
  color: #717477;
}
.live-alerts {
  border-radius: 10px;
  border: solid 2px #d6e6ff;
}
.bg-dark-secondary {
  background-color: #0e1d36;
}
